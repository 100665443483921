import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <footer style={{marginTop:'4vh'}} data-testid="FooterRoot" className="StyledBox-sc-13pk1d4-0 jerTlp sc-p07ff-0 bKhLBq">
      <div className="StyledBox-sc-13pk1d4-0 jqDUOm sc-19avl3p-0 eIVpBL">
        <div className="StyledBox-sc-13pk1d4-0 gqcXiQ">
          <div className="StyledBox-sc-13pk1d4-0 coIBse">
            <ul data-testid="FooterNavList" className="sc-p07ff-2 fODfHg">
              <li><a data-testid="FooterNavListItem"
                className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 sc-p07ff-4 cHzONZ dvuwVg"
                href="https://www.newengen.com/" target="_self" rel="noopener noreferrer">Home</a></li>
              <li><a data-testid="FooterNavListItem"
                className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 sc-p07ff-4 cHzONZ dvuwVg"
                href="https://www.newengen.com/services" target="_self" rel="noopener noreferrer">Services</a></li>
              <li><a data-testid="FooterNavListItem"
                className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 sc-p07ff-4 cHzONZ dvuwVg"
                href="https://www.newengen.com/work" target="_self" rel="noopener noreferrer">Work</a></li>
              <li><a data-testid="FooterNavListItem"
                className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 sc-p07ff-4 cHzONZ dvuwVg"
                href="https://www.newengen.com/insights-hub" target="_self" rel="noopener noreferrer">Insights</a></li>
              <li><a data-testid="FooterNavListItem"
                className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 sc-p07ff-4 cHzONZ dvuwVg"
                href="https://www.newengen.com/company" target="_self" rel="noopener noreferrer">Company</a></li>
              <li><a data-testid="FooterNavListItem"
                className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 sc-p07ff-4 cHzONZ dvuwVg"
                href="https://www.newengen.com/careers" target="_self" rel="noopener noreferrer">Careers</a></li>
              <li><a data-testid="FooterNavListItem"
                className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 sc-p07ff-4 cHzONZ dvuwVg"
                href="https://www.newengen.com/contact" target="_self" rel="noopener noreferrer">Contact</a></li>
            </ul>
          </div>
          <div className="StyledBox-sc-13pk1d4-0 cyJgRU sc-p07ff-1 cDphcG">
            <h4 data-testid="FooterArticleHeadline"
              className="StyledHeading-sc-1rdh4aw-0 kUFomP sc-p07ff-5 bkEXWV">Insights</h4>
            <ul data-testid="FooterArticleList" className="sc-p07ff-2 fODfHg">
              <li><a data-testid="FooterArticleListItem"
                className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 cHzONZ insights-weight"
                href="https://www.newengen.com/insights/new-engen-election-insights-dashboard-2024" target="_self" rel="noopener noreferrer">Introducing the New
                Engen Election Insights Dashboard for 2024</a></li>
              <li><a data-testid="FooterArticleListItem"
                className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 cHzONZ insights-weight"
                href="https://www.newengen.com/insights/the-bill-that-could-ban-tiktok-has-been-signed-into-law-what-now" target="_self" rel="noopener noreferrer">The
                Bill That Could Ban TikTok Has Been Signed Into Law. What Now?</a></li>
              <li><a data-testid="FooterArticleListItem"
                className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 cHzONZ insights-weight"
                href="https://www.newengen.com/insights/new-engen-partners-with-cotopaxi-as-their-digital-agency-of-record" target="_self" rel="noopener noreferrer">New
                Engen Partners with Cotopaxi as their Digital Agency of Record</a></li>
              <li><a data-testid="FooterArticleListItem"
                className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 cHzONZ insights-weight"
                href="https://www.newengen.com/insights/quoted-in-digiday-kelly-dye-offers-guidance-for-tiktok-creators" target="_self" rel="noopener noreferrer">Quoted
                in Digiday: Kelly Dye Offers Guidance for TikTok Creators</a></li>
              <li><a data-testid="FooterArticleListItem"
                className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 cHzONZ insights-weight"
                href="https://www.newengen.com/insights/featured-in-emarketer-kevin-goodwin-on-paid-social-in-h1-2024" target="_self" rel="noopener noreferrer">Featured
                in EMARKETER: Kevin Goodwin on Paid Social in H1 2024</a></li>
              <li><a data-testid="FooterArticleListItem"
                className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 cHzONZ insights-weight"
                href="https://www.newengen.com/insights/celebrating-our-partnership-award-win-best-influencer-marketing-partnership" target="_self" rel="noopener noreferrer">Celebrating
                Our Partnership Award Win: Best Influencer Marketing Partnership</a></li>
            </ul>
          </div>
        </div>
        <div className="StyledBox-sc-13pk1d4-0 jPoHgv">
          <div className="StyledBox-sc-13pk1d4-0 kodrlU"><img alt="Google Partner Badge" height="75"
            src="badge-google-partners.png" /></div>
          <div className="StyledBox-sc-13pk1d4-0 kodrlU"><img alt="Meta Business Partner Badge"
            className="StyledImage-ey4zx9-0 dJMkIM sc-p07ff-6 huUUqV"
            src="badge-meta-business-partner.png" /></div>
          <div className="StyledBox-sc-13pk1d4-0 kodrlU"><img alt="Amazon Badge" height="50"
            src="badge-amazon.png" /></div>
          <div className="StyledBox-sc-13pk1d4-0 kodrlU"><img alt="Microsoft Elite Partner Badge"
            className="StyledImage-ey4zx9-0 dJMkIM sc-p07ff-6 huUUqV"
            src="badge-microsoft-elite-partner.png" /></div>
          <div className="StyledBox-sc-13pk1d4-0 kodrlU"><img alt="Snapchat Badge"
            className="StyledImage-ey4zx9-0 dJMkIM sc-p07ff-6 huUUqV"
            src="badge-snapchat.png" /></div>
          <div className="StyledBox-sc-13pk1d4-0 kodrlU"><img alt="Criteo Partner Badge"
            className="StyledImage-ey4zx9-0 dJMkIM sc-p07ff-6 huUUqV"
            src="badge-criteo.png" /></div>
          <div className="StyledBox-sc-13pk1d4-0 kodrlU"><img alt="Impact Partner Badge"
            className="StyledImage-ey4zx9-0 dJMkIM sc-p07ff-6 huUUqV"
            src="badge-impact-partner.png" /></div>
        </div>
        <div className="StyledBox-sc-13pk1d4-0 eYqvts">
          <div className="StyledBox-sc-13pk1d4-0 cCdpcd sc-p07ff-7 hkqgUs">
            <div className="StyledBox-sc-13pk1d4-0 hoxBYV">
              <div className="StyledBox-sc-13pk1d4-0 hoxBYV"><a target="_self"
                data-testid="FooterLegalItem"
                className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 cHzONZ"
                href="https://www.newengen.com/privacy-policy" rel="noopener noreferrer">Privacy Policy</a></div>
              <div className="StyledBox__StyledBoxGap-sc-13pk1d4-1 gmbFU"></div>
              <div className="StyledBox-sc-13pk1d4-0 hoxBYV"><span> / </span>
                <div className="StyledBox__StyledBoxGap-sc-13pk1d4-1 gmbFU"></div><a target="_self"
                  data-testid="FooterLegalItem"
                  className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 cHzONZ"
                  href="https://www.newengen.com/terms-conditions" rel="noopener noreferrer">Terms &amp; Conditions</a>
              </div>
              <div className="StyledBox__StyledBoxGap-sc-13pk1d4-1 gmbFU"></div>
              <div className="StyledBox-sc-13pk1d4-0 hoxBYV"><span> / </span>
                <div className="StyledBox__StyledBoxGap-sc-13pk1d4-1 gmbFU"></div><a target="_self"
                  data-testid="FooterLegalItem"
                  className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 cHzONZ"
                  href="https://www.newengen.com/health-plan-transparency" rel="noopener noreferrer">Health Plan Transparency in Coverage</a>
              </div>
            </div>
            <div style={{ textAlign: 'left' }} className="StyledBox__StyledBoxGap-sc-13pk1d4-1 hDwzVU">
              <p>Copyright &copy; 2024 New Engen, Inc</p>

            </div>
          </div>
          <div className="StyledBox-sc-13pk1d4-0 hoxBYV sc-p07ff-8 hqNSRw">
            <div className="StyledBox-sc-13pk1d4-0 eWhsik"><a target="_self" rel="noopener noreferrer"
              className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 cHzONZ"
              href="https://www.facebook.com/newengen/"><svg aria-label="Facebook"
                viewBox="0 0 24 24" className="StyledIcon-sc-ofa7kd-0 dxqjDG">
                <path fill="#3B5998" fillRule="evenodd"
                  d="M23 0H1a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h11.75v-9h-3v-3.75h3v-3c0-3.1 1.963-4.625 4.728-4.625 1.324 0 2.463.099 2.794.142v3.24l-1.917.001c-1.504 0-1.855.715-1.855 1.763v2.479h3.75L19.5 15h-3l.06 9H23a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1">
                </path>
              </svg></a></div>
            <div className="StyledBox-sc-13pk1d4-0 eWhsik"><a target="_self" rel="noopener noreferrer"
              className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 cHzONZ"
              href="https://twitter.com/newengen/"><svg aria-label="Twitter" viewBox="0 0 24 24"
                className="StyledIcon-sc-ofa7kd-0 dxqjDG">
                <path fill="#1DA1F2" fillRule="evenodd"
                  d="M24 4.309a9.83 9.83 0 0 1-2.828.775 4.94 4.94 0 0 0 2.165-2.724 9.865 9.865 0 0 1-3.127 1.196 4.925 4.925 0 0 0-8.39 4.49A13.974 13.974 0 0 1 1.671 2.9a4.902 4.902 0 0 0-.667 2.476c0 1.708.869 3.216 2.191 4.099A4.936 4.936 0 0 1 .964 8.86v.06a4.926 4.926 0 0 0 3.95 4.829 4.964 4.964 0 0 1-2.224.085 4.93 4.93 0 0 0 4.6 3.42 9.886 9.886 0 0 1-6.115 2.107c-.398 0-.79-.023-1.175-.068a13.945 13.945 0 0 0 7.548 2.212c9.057 0 14.009-7.503 14.009-14.01 0-.213-.005-.425-.014-.636A10.012 10.012 0 0 0 24 4.309">
                </path>
              </svg></a></div>
            <div className="StyledBox-sc-13pk1d4-0 eWhsik"><a target="_self" rel="noopener noreferrer"
              className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 cHzONZ"
              href="https://www.linkedin.com/company/new-engen-inc./"><svg aria-label="Linkedin"
                viewBox="0 0 24 24" className="StyledIcon-sc-ofa7kd-0 dxqjDG">
                <path fill="#0077B5" fillRule="evenodd"
                  d="M20.452 20.45h-3.56v-5.57c0-1.328-.022-3.036-1.85-3.036-1.851 0-2.134 1.447-2.134 2.942v5.664H9.352V8.997h3.413v1.566h.049c.475-.9 1.636-1.85 3.367-1.85 3.605 0 4.27 2.371 4.27 5.456v6.281zM5.339 7.433a2.063 2.063 0 1 1 0-4.13 2.065 2.065 0 0 1 0 4.13zM7.12 20.45H3.558V8.997H7.12V20.45zM23 0H1a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1z">
                </path>
              </svg></a></div>
            <div className="StyledBox-sc-13pk1d4-0 eWhsik"><a target="_self" rel="noopener noreferrer"
              className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 cHzONZ"
              href="https://www.instagram.com/newengenseattle/"><svg aria-label="Instagram"
                viewBox="0 0 24 24" className="StyledIcon-sc-ofa7kd-0 dxqjDG">
                <path
                  d="M17.318.077c1.218.056 2.06.235 2.838.537a5.36 5.36 0 0 1 1.956 1.274 5.36 5.36 0 0 1 1.274 1.956c.302.779.481 1.62.537 2.838C23.992 8.192 24 8.724 24 12s-.008 3.808-.077 5.318c-.056 1.218-.235 2.06-.537 2.839a5.36 5.36 0 0 1-1.274 1.955 5.359 5.359 0 0 1-1.956 1.274c-.779.302-1.62.481-2.838.537-1.51.069-2.041.077-5.318.077-3.277 0-3.809-.008-5.318-.077-1.218-.056-2.06-.235-2.839-.537a5.359 5.359 0 0 1-1.955-1.274 5.36 5.36 0 0 1-1.274-1.956c-.302-.779-.481-1.62-.537-2.838C.008 15.81 0 15.278 0 12c0-3.277.008-3.81.077-5.318.056-1.218.235-2.06.537-2.838a5.36 5.36 0 0 1 1.274-1.956A5.36 5.36 0 0 1 3.843.614C4.623.312 5.464.133 6.682.077 8.19.008 8.722 0 12 0c3.277 0 3.81.008 5.318.077zM12 2.667c-3.24 0-3.736.007-5.197.074-.927.042-1.483.16-1.994.359a2.73 2.73 0 0 0-1.036.673A2.707 2.707 0 0 0 3.1 4.809c-.198.51-.317 1.067-.359 1.994C2.674 8.264 2.667 8.76 2.667 12s.007 3.736.074 5.197c.042.927.16 1.483.359 1.993.17.436.35.713.673 1.037.324.324.601.504 1.036.673.51.198 1.067.317 1.994.359 1.462.067 1.958.074 5.197.074 3.24 0 3.735-.007 5.197-.074.927-.042 1.483-.16 1.994-.359a2.73 2.73 0 0 0 1.036-.673c.324-.324.504-.601.673-1.036.198-.51.317-1.067.359-1.994.067-1.462.074-1.958.074-5.197s-.007-3.735-.074-5.197c-.042-.927-.16-1.483-.359-1.993a2.709 2.709 0 0 0-.673-1.037A2.708 2.708 0 0 0 19.19 3.1c-.51-.198-1.067-.317-1.994-.359-1.461-.067-1.957-.074-5.197-.074zm0 15.555a6.222 6.222 0 1 1 0-12.444 6.222 6.222 0 0 1 0 12.444zm0-2.666a3.556 3.556 0 1 0 0-7.112 3.556 3.556 0 0 0 0 7.112zm6.222-8.445a1.333 1.333 0 1 1 0-2.667 1.333 1.333 0 0 1 0 2.667z">
                </path>
              </svg></a></div>
            <div className="StyledBox-sc-13pk1d4-0 eWhsik"><a target="_self" rel="noopener noreferrer"
              className="StyledAnchor-sc-1rp7lwl-0 bEJkaP sc-p07ff-3 cHzONZ"
              href="https://www.glassdoor.com/Overview/Working-at-New-Engen-EI_IE2080483.11,20.htm"><svg
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                className="sc-p07ff-9 klewVi">
                <path
                  d="M17.144 20.572H3.43A3.427 3.427 0 006.856 24h10.286a3.428 3.428 0 003.428-3.428V6.492a.123.123 0 00-.124-.125h-3.18a.125.125 0 00-.123.126v14.08zm0-20.572a3.429 3.429 0 013.427 3.43H6.858v14.078a.126.126 0 01-.125.125H3.554a.125.125 0 01-.125-.125V3.428A3.429 3.429 0 016.856 0h10.287">
                </path>
              </svg></a></div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer