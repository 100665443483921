import React from "react";

import "./report.css";

const Report = () => (
  <div className="crmUserAnalytics-component">
    <iframe
    className="report"
      // src="https://lookerstudio.google.com/embed/reporting/03f91508-e073-4c43-b313-c6897f344818/page/SkbiB"
      // src="https://lookerstudio.google.com/embed/reporting/bb111915-ee41-4570-83db-c5b784b32eef/page/p_1anoj5u0fd"
      // src="https://lookerstudio.google.com/embed/reporting/1ed0ee76-5beb-4567-ac69-db5eb0c55c21/page/p_1anoj5u0fd"
      // src="https://lookerstudio.google.com/embed/reporting/1ed0ee76-5beb-4567-ac69-db5eb0c55c21/page/p_1anoj5u0fd"
      src="https://lookerstudio.google.com/embed/reporting/3773aa6f-d004-4297-9d33-151b487b14c9/page/p_1anoj5u0fd"
      title="CRM User Analytics"
    />
  </div>
);

export default Report;
