import React, { useState } from 'react';
import './Navbar.css'; // Import CSS file for styling
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';


const Navbar = () => {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <div>
      <nav className="navbar">
        <div className="navbar-logo">
          <a className='logo' href='https://www.newengen.com' target="_self" rel="noopener noreferrer">
            <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1242.05 233.78"
              className="sc-1q8rpq7-2 gBUlXs">
              <path d="M174.64 169.36l-57.87 31.72-.24 21.63c1.25-.53 67.2-37 67.2-37zM88.91 32.7l.49-22.48-66.82 36.77 9.39 16.6L88.91 32.7z"></path>
              <path d="M113.33 48.62l75.63 133.64 16.89-9.27-1.33-123.06-52 28.8-38.55-68.09v.07L108.05 0 94.7 7.35l-2 104.42-26.31 14.32 9.49 16.76 16.4-8.85-.95 50.22C83.81 170.71 17.02 50.09 17.02 50.09l-17 9.36c.1 6.4 1.8 121.51 1.76 123.83 6.93-3.85 13.73-7.46 20.49-11.21v-.06l31-16.43 43.16 78.18s4.92-2.6 13.44-7.35l2-103 27.77-15-9.47-17.04-17.87 9.72.82-41.89zm73.84 33.46l-1 56.23-24-42.53zM21.54 150.52L19.7 95.04l1.68 3 22.52 40.62v.07zm344.5-14.44l-55.57-71.73-16.89 8.36v95.38h17.92v-73.8l57.21 73.8h15.27V64.35l-17.94 8.36v63.37zm62.84-12.15h52.02v-16.31h-52.02V80.65h50.33l8.36-16.3h-76.92v103.74h77.66v-16.3h-59.43v-27.86zm183.91 17.34L587.3 64.06h-15.41l-25.49 77.21-24.75-76.92h-19.71l36.16 104.48h15.71l25.49-74.54 25.49 74.54h15.71l36.16-104.48h-19.12l-24.75 76.92zm125.23-17.34h52.02v-16.31h-52.02V80.65h50.34l8.35-16.3H719.8v103.74h77.65v-16.3h-59.43v-27.86zm153.53 12.15l-55.57-71.73-16.89 8.36v95.38h17.93v-73.8l57.21 73.8h15.26V64.35l-17.94 8.36v63.37zM984.48 126h26.53v19.31a39.36 39.36 0 01-11.19 5.68 43.94 43.94 0 01-13.86 2.13 35.88 35.88 0 01-14.45-2.8 34.1 34.1 0 01-11.12-7.7 33.52 33.52 0 01-7.18-11.68 42.07 42.07 0 01-2.52-14.79v-.3a38.34 38.34 0 012.59-14.05 37.85 37.85 0 017.11-11.68 33.08 33.08 0 0110.68-7.91 30.71 30.71 0 0113.26-2.89 50.7 50.7 0 019 .74 34.2 34.2 0 017.48 2.23 42 42 0 016.52 3.55 66.49 66.49 0 016.08 4.6l11.55-13.79a69.07 69.07 0 00-8.14-6 48.42 48.42 0 00-9-4.37 58.47 58.47 0 00-10.37-2.74 72 72 0 00-12.38-1 53.45 53.45 0 00-21.56 4.3 52.13 52.13 0 00-28 28.68 54.54 54.54 0 00-3.93 20.67v.3a57 57 0 003.85 21 49.36 49.36 0 0027.87 28.24 57.74 57.74 0 0022.08 4.07 65.25 65.25 0 0013.11-1.26 64.55 64.55 0 0011.56-3.48 63.74 63.74 0 0010.08-5.19 77.34 77.34 0 008.52-6.22v-43.5h-35.81zm86.1-2.07h52.02v-16.31h-52.02V80.65h50.33l8.36-16.3h-76.92v103.74h77.66v-16.3h-59.43v-27.86zm153.53-51.22v63.37l-55.57-71.73-16.89 8.36v95.38h17.92v-73.8l57.21 73.8h15.27V64.35l-17.94 8.36z"></path>
            </svg>
          </a>
        </div>
        <div className="burger-menu" onClick={toggleMenu}>
          {menuActive ? <CloseIcon style={{ color: 'white' }} /> : <MenuIcon style={{ color: 'white' }} />}
        </div>
        <ul className={`navbar-menu ${menuActive ? 'active' : ''}`}>
          <li className='navbar-menu-right first-child navbar-menu-list'><a href='https://www.newengen.com/services' target="_self" rel="noopener noreferrer" className='link'>Services</a></li>
          <li className='navbar-menu-right navbar-menu-list'><a href='https://www.newengen.com/work' target="_self" rel="noopener noreferrer" className='link'>Work</a></li>
          <li className='navbar-menu-right navbar-menu-list'><a href='https://www.newengen.com/insights-hub' target="_self" rel="noopener noreferrer" className='link'>Insights</a></li>
          <li className='navbar-menu-right navbar-menu-list'><a href='https://www.newengen.com/company' target="_self" rel="noopener noreferrer" className='link'>Company</a></li>
          <li className='navbar-menu-right navbar-menu-list'><a href='https://www.newengen.com/careers' target="_self" rel="noopener noreferrer" className='link'>Careers</a></li>
          <li className='contact navbar-menu-list'><a href='https://www.newengen.com/contact' target="_self" rel="noopener noreferrer" className='link'>Contact</a></li>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
